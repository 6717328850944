import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7e684b5f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "commodity-list-wrap"
};
const _hoisted_2 = {
  class: "commodity-list-content"
};
const _hoisted_3 = {
  key: 0,
  class: "page"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_t_input_search = _resolveComponent("t-input-search");
  const _component_product_list = _resolveComponent("product-list");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _component_recommend_search_questions = _resolveComponent("recommend-search-questions");
  const _component_t_layout = _resolveComponent("t-layout");
  return _openBlock(), _createBlock(_component_t_layout, {
    class: "commodity-list",
    "is-white-bg": "",
    "is-header-padding": "",
    "is-custome-header-center": "",
    "wise-header-size": "small"
  }, {
    "header-center": _withCtx(() => [_createVNode(_component_t_input_search, {
      value: _ctx.query,
      "replace-query-on-search": !_ctx.isCommodityPage,
      onSearch: _ctx.handleSearch,
      onClear: _ctx.clearSearch
    }, null, 8, ["value", "replace-query-on-search", "onSearch", "onClear"])]),
    default: _withCtx(() => {
      var _ctx$recommendSearchQ;
      return [_createElementVNode("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_product_list, {
        loading: _ctx.loading,
        "more-data-loading": _ctx.moreDataLoading,
        list: _ctx.commodityList,
        total: _ctx.total,
        "questions-map": _ctx.recommendQuestionsMap,
        onLoadMore: _ctx.onProductListLoadMore
      }, null, 8, ["loading", "more-data-loading", "list", "total", "questions-map", "onLoadMore"])]), _ctx.loadMode === 'pagination' && _ctx.total > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_pagination, {
        layout: "prev, pager, next",
        total: _ctx.total,
        small: _ctx.isMobile,
        "page-size": _ctx.PAGE_SIZE,
        "pager-count": _ctx.isMobile ? 5 : 7,
        background: "",
        onCurrentChange: _ctx.handleChangePagination
      }, null, 8, ["total", "small", "page-size", "pager-count", "onCurrentChange"])])) : _createCommentVNode("", true), _ctx.loadMode === 'pagination' && (_ctx$recommendSearchQ = _ctx.recommendSearchQuestions) !== null && _ctx$recommendSearchQ !== void 0 && _ctx$recommendSearchQ.length ? (_openBlock(), _createBlock(_component_recommend_search_questions, {
        key: 1,
        "screen-type": _ctx.isMobile ? 'mobile' : 'pc',
        list: _ctx.recommendSearchQuestions
      }, null, 8, ["screen-type", "list"])) : _createCommentVNode("", true)])];
    }),
    _: 1
  });
}