/* unplugin-vue-components disabled */import { defineComponent, onMounted, computed } from 'vue';
import TImage from '@/components/TImage.vue';
import TButton from '@/components/TButton/index.vue';
import { isMobileUa, highlightword } from '@/base/utils';
import { generatePrice } from '@/base/utils/price';
import { scLog } from '@/base/log/sclog';
import { useDialPhone } from '@/base/hooks/useDialPhone';
export default defineComponent({
  name: 'NewsRecommendProductItem',
  props: {
    item: {
      type: Object,
      default: undefined
    },
    // 推荐商品的飘红关键词
    keywords: {
      type: String,
      default: ''
    },
    // 埋点的一些信息
    logInfo: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TImage,
    TButton
  },
  setup(props) {
    const isMobile = isMobileUa();
    const productNameHtml = computed(() => {
      var _props$item;
      return highlightword({
        content: ((_props$item = props.item) === null || _props$item === void 0 ? void 0 : _props$item.name) || '',
        keywords: props.keywords || ''
      });
    });
    /**
     * 是否显示 '获取底价' || '拨打电话' 按钮
     * pc 端展示获取底价按钮 移动端展示拨打电话按钮; 当是移动端时，且数据里没有 solution 或 phone400 时，不展示拨打电话按钮
     */
    const isInquiryBtnVisible = computed(() => {
      var _props$item2, _props$item3, _props$item4;
      return !isMobile || ((_props$item2 = props.item) === null || _props$item2 === void 0 || (_props$item2 = _props$item2.solution) === null || _props$item2 === void 0 ? void 0 : _props$item2.encrSellerId) && ((_props$item3 = props.item) === null || _props$item3 === void 0 || (_props$item3 = _props$item3.solution) === null || _props$item3 === void 0 ? void 0 : _props$item3.id) || ((_props$item4 = props.item) === null || _props$item4 === void 0 ? void 0 : _props$item4.phone400);
    });
    /**
     * 价格加上单位
     */
    const formatPrice = computed(() => {
      var _props$item5, _props$item6;
      const price = generatePrice({
        price: ((_props$item5 = props.item) === null || _props$item5 === void 0 ? void 0 : _props$item5.price) || '',
        currency: ((_props$item6 = props.item) === null || _props$item6 === void 0 ? void 0 : _props$item6.priceCurrency) || ''
      });
      return price;
    });
    const {
      init,
      handleShowPhone
    } = useDialPhone();
    const isMounted = ref(false);
    onMounted(() => {
      isMounted.value = true;
    });
    /**
     * 只有在有必要时才初始化营销中台电话 sdk
     * 必要条件：移动端 且 有 solution 或 phone400
     */
    watchEffect(() => {
      var _props$item7, _props$item8, _props$item9;
      const isShowPhone = isMobile && (((_props$item7 = props.item) === null || _props$item7 === void 0 || (_props$item7 = _props$item7.solution) === null || _props$item7 === void 0 ? void 0 : _props$item7.encrSellerId) && ((_props$item8 = props.item) === null || _props$item8 === void 0 || (_props$item8 = _props$item8.solution) === null || _props$item8 === void 0 ? void 0 : _props$item8.id) || ((_props$item9 = props.item) === null || _props$item9 === void 0 ? void 0 : _props$item9.phone400));
      if (isShowPhone && isMounted.value) {
        var _props$item10, _props$item11;
        // 初始化营销中台电话 sdk
        init({
          phone: (_props$item10 = props.item) === null || _props$item10 === void 0 ? void 0 : _props$item10.phone400,
          solution: (_props$item11 = props.item) === null || _props$item11 === void 0 ? void 0 : _props$item11.solution
        });
      }
    });
    /**
     * 获取埋点上报的 ext 参数
     * product_recommend_query: 推荐商品的推荐策略
     */
    function getSendLogExt() {
      var _props$logInfo;
      // product_recommend_query: 推荐商品的推荐策略
      const ext = (_props$logInfo = props.logInfo) !== null && _props$logInfo !== void 0 && _props$logInfo.product_recommend_query ? {
        product_recommend_query: props.logInfo.product_recommend_query
      } : {};
      return ext;
    }
    function handleClick() {
      var _props$logInfo2, _props$logInfo$pos, _props$logInfo3;
      // 上报的 pos 结构为：组件标识_索引 例如：'top-recommend___1', right-recommend___1
      const alias = ((_props$logInfo2 = props.logInfo) === null || _props$logInfo2 === void 0 ? void 0 : _props$logInfo2.alias) || '';
      const index = (_props$logInfo$pos = (_props$logInfo3 = props.logInfo) === null || _props$logInfo3 === void 0 ? void 0 : _props$logInfo3.pos) !== null && _props$logInfo$pos !== void 0 ? _props$logInfo$pos : -1;
      const ext = getSendLogExt();
      scLog('click', {
        elem: 'product-item-click',
        pos: `${alias}___${index}`,
        ext: JSON.stringify(ext)
      });
    }
    /**
     * 获取底价 或 拨打电话
     * 1. 判断是否为移动端，如果是则直接拨打电话
     * 2. 否则跳转到爱采购全网询价页面
     */
    function handleCallPhoneOrToB2bInquiry(item) {
      var _props$logInfo4, _props$logInfo$pos2, _props$logInfo5;
      // 埋点上报参数: 上报的 pos 结构为：组件标识_索引 例如：'top-recommend___1', right-recommend___1
      const alias = ((_props$logInfo4 = props.logInfo) === null || _props$logInfo4 === void 0 ? void 0 : _props$logInfo4.alias) || '';
      const index = (_props$logInfo$pos2 = (_props$logInfo5 = props.logInfo) === null || _props$logInfo5 === void 0 ? void 0 : _props$logInfo5.pos) !== null && _props$logInfo$pos2 !== void 0 ? _props$logInfo$pos2 : -1;
      const ext = getSendLogExt();
      // 非移动端是获取底价 -> 跳转到爱采购全网询价页面
      if (!isMobile) {
        if (item.inquiry_url) {
          const url = window.location.origin;
          window.open(`${url}${item.inquiry_url}`, '_blank');
        }
        scLog('click', {
          elem: 'product-item-inquiry-click',
          pos: `${alias}___${index}`,
          ext: JSON.stringify(ext)
        });
        return;
      }
      scLog('click', {
        elem: 'product-item-phone-click',
        pos: `${alias}___${index}`,
        ext: JSON.stringify(ext)
      });
      // 移动端直接拨打电话
      handleShowPhone();
    }
    return {
      isMobile,
      productNameHtml,
      isInquiryBtnVisible,
      formatPrice,
      handleClick,
      handleCallPhoneOrToB2bInquiry
    };
  }
});