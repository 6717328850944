/* unplugin-vue-components disabled */import "core-js/modules/es.array.push.js";
import { useHead } from '@vueuse/head';
import xss from 'xss';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElPagination } from 'element-plus';
import ProductList from './components/ProductList.vue';
import TLayout from '@/components/Layout/Layout.vue';
import TInputSearch from '@/components/TInputSearch/index.vue';
import RecommendSearchQuestions from '@/views/components/RecommendSearchQuestions/Index.vue';
import { apmLog } from '@/base/hooks/useApm/apmLog';
import { NewsMutationEnum } from '@/store/news';
import useStoreRequest from '@/base/hooks/useStoreRequest';
import { getNewCommodityList } from '@/base/api/news';
import { DEFAULT_SEO_CONFIG, getSeoConfig } from '@/base/config/seo';
import { IS_NODE, isMobileUa } from '@/base/utils';
import { useSafeSSRContext } from '@/base/hooks/useSafeSSRContext';
const PAGE_SIZE = 20;
/* eslint-disable-next-line */
export default defineComponent({
  name: 'CommodityList',
  components: {
    TLayout,
    TInputSearch,
    RecommendSearchQuestions,
    ProductList,
    ElPagination
  },
  setup() {
    apmLog();
    useHead(DEFAULT_SEO_CONFIG);
    const isMobile = isMobileUa();
    const router = useRouter();
    const route = useRoute();
    const ssrCtx = useSafeSSRContext();
    // 加载模式 infinite: 无限滚动, pagination: 分页加载
    const loadMode = isMobile ? 'infinite' : 'pagination';
    const pageId = ref(route.params.page_id);
    const query = ref(route.query.query);
    const page = ref(Number(route.query.page) || 1);
    const recommendQuestionsMap = reactive({});
    const isCommodityPage = computed(() => route.name === 'CommodityPage');
    // page_id || query
    const baseParams = computed(() => route.name === 'CommodityPage' ? {
      page_id: pageId.value
    } : {
      query: query.value
    });
    const {
      state,
      getData
    } = useStoreRequest({
      space: 'news',
      mutationName: NewsMutationEnum.SET_NEWS_COMMODITY_LIST_DATA,
      stateKey: 'newsCommodityListData',
      handlePayload: (data, state) => {
        var _data$items, _data$recom_list_page;
        // 分页模式或者重新从第一页拉取数据时，采用新数据替换旧数据
        if (loadMode === 'pagination') {
          return data;
        }
        if (!(data !== null && data !== void 0 && (_data$items = data.items) !== null && _data$items !== void 0 && _data$items.length)) {
          return data;
        }
        // 无限下拉模式下，将新数据追加到旧数据后面
        const items = (data === null || data === void 0 ? void 0 : data.page) === 1 ? data.items || [] : [...((state === null || state === void 0 ? void 0 : state.items) || []), ...((data === null || data === void 0 ? void 0 : data.items) || [])];
        // 无限下拉时，每页的最后要展示 '大家还搜过' 模块，这里使用 isPageEnd 做标记，并使用 page 记录当前是哪一页的最后一条
        const currPage = (data === null || data === void 0 ? void 0 : data.page) || page.value || 0;
        items[items.length - 1].isPageEnd = true;
        items[items.length - 1].page = currPage;
        // 无限下拉模式下，将 '大家还搜过' 的数据保存到以对应页为 key 的对象中，方便在分页时使用
        if (data !== null && data !== void 0 && (_data$recom_list_page = data.recom_list_page) !== null && _data$recom_list_page !== void 0 && _data$recom_list_page.length) {
          Object.assign(recommendQuestionsMap, {
            [`page_${currPage}`]: data.recom_list_page
          });
        }
        return {
          ...data,
          items
        };
      }
    }, getNewCommodityList, {
      ...baseParams.value,
      page: page.value,
      page_size: PAGE_SIZE
    }, {
      onError(e) {
        if ((e === null || e === void 0 ? void 0 : e.errno) === 4001) {
          if (IS_NODE) {
            ssrCtx.status = 404;
          } else {
            router.replace({
              name: 'Error404'
            });
          }
        }
      },
      onSuccess(res) {
        var _res$data;
        if (!query.value && typeof ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.query) === 'string') {
          query.value = res.data.query;
        }
      }
    });
    const data = computed(() => state.value);
    const commodityList = computed(() => {
      var _data$value$data;
      return ((_data$value$data = data.value.data) === null || _data$value$data === void 0 ? void 0 : _data$value$data.items) || [];
    });
    // 是否正在加载数据（会出现骨架屏）
    const loading = computed(() => {
      var _commodityList$value;
      // 如果是 infinite 模式，且已经有数据了，就不显示 loading
      if (loadMode === 'infinite' && (_commodityList$value = commodityList.value) !== null && _commodityList$value !== void 0 && _commodityList$value.length) {
        return false;
      }
      return data.value.isLoading;
    });
    // 是否正在加载更多数据（只会在列表底部出现加载文本，不会出现骨架屏）
    const moreDataLoading = computed(() => {
      var _commodityList$value2;
      if (loadMode === 'pagination' || !((_commodityList$value2 = commodityList.value) !== null && _commodityList$value2 !== void 0 && _commodityList$value2.length)) {
        return false;
      }
      return data.value.isLoading;
    });
    const total = computed(() => {
      var _data$value$data2;
      return ((_data$value$data2 = data.value.data) === null || _data$value$data2 === void 0 ? void 0 : _data$value$data2.total) || 0;
    });
    const seo = computed(() => {
      var _data$value$data3;
      return (_data$value$data3 = data.value.data) === null || _data$value$data3 === void 0 ? void 0 : _data$value$data3.seo;
    });
    // 分页和无限下拉模式下的 '大家还搜过' 的数据结构不一样，这里是主要是用在分页时的数据格式
    const recommendSearchQuestions = computed(() => {
      var _data$value$data4;
      return loadMode === 'infinite' ? recommendQuestionsMap[`page_${page.value}`] || [] : ((_data$value$data4 = data.value.data) === null || _data$value$data4 === void 0 ? void 0 : _data$value$data4.recom_list_page) || [];
    });
    /**
     * 监听数据的变化  给 recommendQuestionsMap 赋值
     * NOTICE: 这里主要是解决 SSR 时 首次加载数据时，上面 useStoreRequest 方法中 handlePayload 不会在客户端执行，
     * 因此造成 recommendQuestionsMap 在客户端又初始化为空对象问题(SSR时有数据，客户端渲染时又执行 setup，使数据又变成了空对象)
     */
    watch(() => data.value.data, newVal => {
      var _newVal$recom_list_pa;
      if (newVal !== null && newVal !== void 0 && (_newVal$recom_list_pa = newVal.recom_list_page) !== null && _newVal$recom_list_pa !== void 0 && _newVal$recom_list_pa.length) {
        const currPage = newVal.page || page.value || 0;
        if (currPage !== 1) {
          return;
        }
        Object.assign(recommendQuestionsMap, {
          [`page_${currPage}`]: newVal.recom_list_page
        });
      }
    }, {
      immediate: true,
      deep: true
    });
    useHead(computed(() => {
      var _seo$value, _seo$value$keywords$s, _seo$value2, _seo$value2$split, _seo$value3;
      return getSeoConfig({
        // ...seo.value || DEFAULT_SEO_CONFIG,
        title: xss(((_seo$value = seo.value) === null || _seo$value === void 0 ? void 0 : _seo$value.title) || '出海易'),
        keywords: (_seo$value$keywords$s = (_seo$value2 = seo.value) === null || _seo$value2 === void 0 || (_seo$value2 = _seo$value2.keywords) === null || _seo$value2 === void 0 || (_seo$value2$split = _seo$value2.split) === null || _seo$value2$split === void 0 ? void 0 : _seo$value2$split.call(_seo$value2, ',')) !== null && _seo$value$keywords$s !== void 0 ? _seo$value$keywords$s : [],
        description: (_seo$value3 = seo.value) === null || _seo$value3 === void 0 ? void 0 : _seo$value3.description
      });
    }));
    const handleSearch = value => {
      if (value) {
        if (isCommodityPage.value) {
          router.push({
            name: 'CommodityList',
            query: {
              query: value
            }
          });
        }
        query.value = value;
        page.value = 1;
        getData({
          page: page.value,
          query: query.value,
          page_size: PAGE_SIZE
        });
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };
    const handleChangePagination = currentPage => {
      getData({
        ...baseParams.value,
        page: currentPage,
        page_size: PAGE_SIZE
      });
    };
    const clearSearch = () => {};
    const onProductListLoadMore = async () => {
      var _res$data2;
      if (loadMode !== 'infinite') {
        return;
      }
      const res = await getData({
        ...baseParams.value,
        page: page.value + 1,
        page_size: PAGE_SIZE
      });
      if (res !== null && res !== void 0 && (_res$data2 = res.data) !== null && _res$data2 !== void 0 && (_res$data2 = _res$data2.items) !== null && _res$data2 !== void 0 && _res$data2.length) {
        page.value += 1;
      }
    };
    onMounted(() => {
      var _data$value$data5;
      if (!query.value && typeof ((_data$value$data5 = data.value.data) === null || _data$value$data5 === void 0 ? void 0 : _data$value$data5.query) === 'string') {
        var _data$value$data6;
        query.value = (_data$value$data6 = data.value.data) === null || _data$value$data6 === void 0 ? void 0 : _data$value$data6.query;
      }
    });
    return {
      loadMode,
      PAGE_SIZE,
      isCommodityPage,
      isMobile,
      baseParams,
      query,
      loading,
      moreDataLoading,
      commodityList,
      total,
      recommendQuestionsMap,
      recommendSearchQuestions,
      clearSearch,
      handleSearch,
      handleChangePagination,
      onProductListLoadMore
    };
  }
});