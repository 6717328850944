import { defineComponent } from 'vue';
export default defineComponent({
  name: 'RecommendSearchQuestions',
  props: {
    screenType: {
      type: String,
      default: 'pc'
    },
    title: {
      type: String,
      default: '大家还搜过'
    },
    list: {
      type: Array,
      default: () => []
    }
  }
});