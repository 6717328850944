import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("section", {
    class: _normalizeClass(['questions', `questions-${_ctx.screenType}`])
  }, [_createElementVNode("header", {
    class: _normalizeClass([`questions-${_ctx.screenType}__header`])
  }, _toDisplayString(_ctx.title), 3), _createElementVNode("div", {
    class: _normalizeClass([`questions-${_ctx.screenType}__container`])
  }, [_createElementVNode("div", {
    class: _normalizeClass([`questions-${_ctx.screenType}__list`])
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, item => {
    return _openBlock(), _createElementBlock("span", {
      key: `${item.title}`,
      class: "item"
    }, [_createVNode(_component_router_link, {
      to: item.url,
      target: "_blank"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.title), 1)]),
      _: 2
    }, 1032, ["to"])]);
  }), 128))], 2)], 2)], 2);
}