import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-cd7ecacc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "product-item"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = {
  class: "product-info"
};
const _hoisted_4 = ["href"];
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = ["href"];
const _hoisted_7 = {
  class: "product-tags"
};
const _hoisted_8 = {
  key: 0,
  class: "product-address"
};
const _hoisted_9 = {
  class: "product-bottom"
};
const _hoisted_10 = {
  key: 0,
  class: "product-price"
};
const _hoisted_11 = {
  key: 0,
  class: "price-sign"
};
const _hoisted_12 = {
  class: "price-count"
};
const _hoisted_13 = {
  key: 1,
  class: "product-inquiry"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$item, _ctx$item2, _ctx$item6, _ctx$item7, _ctx$item8, _ctx$item9, _ctx$item10, _ctx$item11, _ctx$item12;
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item");
  const _component_t_image = _resolveComponent("t-image");
  const _component_el_skeleton = _resolveComponent("el-skeleton");
  const _component_t_button = _resolveComponent("t-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("a", {
    href: ((_ctx$item = _ctx.item) === null || _ctx$item === void 0 ? void 0 : _ctx$item.jump_url) || `https://www.overseadia.com/en-US/detail/${(_ctx$item2 = _ctx.item) === null || _ctx$item2 === void 0 ? void 0 : _ctx$item2.id}`,
    rel: "nofollow",
    target: "_blank",
    class: "product-image",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
    //@ts-ignore
    (...args) => _ctx.handleClick && _ctx.handleClick(...args), ["stop"]))
  }, [_createVNode(_component_el_skeleton, {
    loading: _ctx.loading
  }, {
    template: _withCtx(() => [_createVNode(_component_el_skeleton_item, {
      variant: "image"
    })]),
    default: _withCtx(() => {
      var _ctx$item$image, _ctx$item3;
      return [_createVNode(_component_t_image, {
        "img-src": (_ctx$item$image = (_ctx$item3 = _ctx.item) === null || _ctx$item3 === void 0 ? void 0 : _ctx$item3.image) !== null && _ctx$item$image !== void 0 ? _ctx$item$image : '',
        "action-w": 448,
        ratio: "100%",
        preload: "",
        class: "img-box"
      }, null, 8, ["img-src"])];
    }),
    _: 1
  }, 8, ["loading"])], 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_skeleton, {
    loading: _ctx.loading,
    animated: ""
  }, {
    template: _withCtx(() => [_createVNode(_component_el_skeleton_item, {
      variant: "text",
      class: "product-name__skeleton",
      style: {
        "width": "80%",
        "margin-top": "8px"
      }
    }), _createVNode(_component_el_skeleton_item, {
      variant: "text",
      class: "product-name__skeleton",
      style: {
        "width": "30%"
      }
    })]),
    default: _withCtx(() => {
      var _ctx$item4, _ctx$item5;
      return [_createElementVNode("a", {
        href: ((_ctx$item4 = _ctx.item) === null || _ctx$item4 === void 0 ? void 0 : _ctx$item4.jump_url) || `https://www.overseadia.com/en-US/detail/${(_ctx$item5 = _ctx.item) === null || _ctx$item5 === void 0 ? void 0 : _ctx$item5.id}`,
        rel: "nofollow",
        target: "_blank",
        class: "product-name",
        onClick: _cache[1] || (_cache[1] =
        //@ts-ignore
        (...args) => _ctx.handleClick && _ctx.handleClick(...args))
      }, [_createElementVNode("span", {
        class: "name",
        innerHTML: _ctx.productNameHtml
      }, null, 8, _hoisted_5)], 8, _hoisted_4)];
    }),
    _: 1
  }, 8, ["loading"]), (_ctx$item6 = _ctx.item) !== null && _ctx$item6 !== void 0 && _ctx$item6.tag_list && _ctx.item.tag_list.length || (_ctx$item7 = _ctx.item) !== null && _ctx$item7 !== void 0 && _ctx$item7.price || (_ctx$item8 = _ctx.item) !== null && _ctx$item8 !== void 0 && _ctx$item8.address ? (_openBlock(), _createElementBlock("a", {
    key: 0,
    href: ((_ctx$item9 = _ctx.item) === null || _ctx$item9 === void 0 ? void 0 : _ctx$item9.jump_url) || `https://www.overseadia.com/en-US/detail/${(_ctx$item10 = _ctx.item) === null || _ctx$item10 === void 0 ? void 0 : _ctx$item10.id}`,
    rel: "nofollow",
    target: "_blank",
    onClick: _cache[3] || (_cache[3] = _withModifiers(
    //@ts-ignore
    (...args) => _ctx.handleClick && _ctx.handleClick(...args), ["stop"]))
  }, [_createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.tag_list || [], tag => {
    return _openBlock(), _createElementBlock("span", {
      key: tag,
      class: "product-tag"
    }, _toDisplayString(tag), 1);
  }), 128)), (_ctx$item11 = _ctx.item) !== null && _ctx$item11 !== void 0 && _ctx$item11.address ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.item.address), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_9, [(_ctx$item12 = _ctx.item) !== null && _ctx$item12 !== void 0 && _ctx$item12.price ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_ctx.item.price !== '面议' ? (_openBlock(), _createElementBlock("span", _hoisted_11, "￥")) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.formatPrice), 1)])) : _createCommentVNode("", true), _ctx.isInquiryBtnVisible ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(_component_t_button, {
    type: "primary",
    size: _ctx.isMobile ? 'small' : 'default',
    plain: _ctx.isMobile,
    round: _ctx.isMobile,
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => _ctx.handleCallPhoneOrToB2bInquiry(_ctx.item), ["prevent", "stop"]))
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.isMobile ? '拨打电话' : '获取底价'), 1)]),
    _: 1
  }, 8, ["size", "plain", "round"])])) : _createCommentVNode("", true)])], 8, _hoisted_6)) : _createCommentVNode("", true)])]);
}