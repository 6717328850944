import { defineComponent, toRefs } from 'vue';
import ProductItem from '@/views/News/components/Recommend/ProductItem.vue';
import RecommendSearchQuestions from '@/views/components/RecommendSearchQuestions/Index.vue';
export default defineComponent({
  name: 'ProductList',
  components: {
    ProductItem,
    RecommendSearchQuestions
  },
  emits: ['load-more'],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    moreDataLoading: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    // 组件的别名 (可为埋点上报使用的 区分在哪个位置引用的此组件上报的数据)
    componentAlias: {
      type: String,
      default: 'commodity-list'
    },
    questionsMap: {
      type: Object,
      default: () => ({})
    },
    skeletonNum: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    }
  },
  setup(props, {
    emit
  }) {
    const {
      list,
      total
    } = toRefs(props);
    const onInfiniteScroll = () => {
      if (props.moreDataLoading || props.loading || list.value.length >= total.value) {
        return;
      }
      emit('load-more');
    };
    return {
      onInfiniteScroll
    };
  }
});