/**
 * @file useDialPhone
 * @author yuanxiaoshuai@baidu.com
 */
import {ref, reactive} from 'vue';
import {showElMessage} from '@/base/utils/message';
import {IS_NODE} from '@/base/utils';

const phoneTracker = () => import(/* webpackChunkName: "fmc_phone_sdk" */ '@baidu/fmc-phone-sdk/es/h5');
const B2B_SOURCE_TYPE = '49001';

export function useDialPhone() {
    interface IStateData {
        phone?: string;
        solution?: {
            encrSellerId: string;
            id: string;
        };
    }
    const instance = ref();
    const key = ref();
    const phoneLoading = ref(false);
    const phoneNumber = ref();
    const stateData = reactive<IStateData>({
        phone: '',
        solution: undefined,
    });
    /**
     * 初始化组件
     */
    async function init(option: IStateData) {
        // 400 电话直接调起拨号 不用去调用营销中台电话sdk
        if (option.phone) {
            stateData.phone = option.phone;
            return;
        }
        if (!option.solution) {
            return;
        }

        stateData.phone = option.phone || '';
        stateData.solution = option.solution || {};

        const {
            encrSellerId,
            id,
        } = option.solution || {encrSellerId: '', id: ''};
        const PhoneTrackerTarget = await phoneTracker();
        // eslint-disable-next-line @babel/new-cap
        instance.value = new PhoneTrackerTarget.default({
            source: B2B_SOURCE_TYPE,
        });
        key.value = instance.value.init({
            uid: encrSellerId,
            realPhones: '',
            solutionIds: id,
        });
    }
    /**
     * 拨号
     */
    function makePhoneCall() {
        if (IS_NODE) {
            return;
        }
        const h5Call = () => {
            window.location.href = `tel:${phoneNumber.value}`;
        };
        h5Call();
    }

    /**
     * 请求处理
     */
    function handleAjaxSuccess({phone}) {
        if (!phone) {
            showElMessage({
                type: 'error',
                center: true,
                message: '电话获取失败，请重试',
                duration: 1000,
            });
            return;
        }
        phoneNumber.value = phone;
        makePhoneCall();
    }

    /**
     * 发起电话请求
     */
    async function getPhoneAjax() {
        phoneLoading.value = true;
        if (stateData.solution && instance.value?.getCallNumber) {
            instance.value.getCallNumber({
                uid: stateData.solution.encrSellerId,
                realPhone: '',
                solutionId: stateData.solution.id,
                key: key.value,
            }).then(res => {
                phoneLoading.value = false;
                // 获取拨打号码
                const {virtualPhone} = res;
                if (virtualPhone) {
                    handleAjaxSuccess({phone: virtualPhone});
                }
            }).catch(e => {
                phoneLoading.value = false;
                showElMessage({
                    type: 'error',
                    center: true,
                    message: '电话获取失败，请重试',
                    duration: 1000,
                });
            });
        }
    }

    /**
     * 展示电话
     */
    async function handleShowPhone() {
        // 400 电话直接调起拨号 不用去调用营销中台电话sdk
        if (stateData.phone) {
            phoneNumber.value = stateData.phone;
            makePhoneCall();
            return;
        }
        if (phoneLoading.value) {
            return;
        }
        // 营销中台电话 sdk 获取虚拟电话号码并调起拨号
        await getPhoneAjax();
    }

    return {
        init,
        handleShowPhone,
    };
}

