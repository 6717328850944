/**
 * @file price 的工具函数集
 * @author liuqifeng01.baidu.com
 *
 * TODO 单测
 */

/**
 * 生成价格字符串
 *
 * @param price 价格
 * @param currency 货币单位
 * @returns 返回价格字符串
 */
export function generatePrice(info: {price: string, currency?: string}): string {
    let price = info.price || '';
    if (price === '面议') {
        return price;
    }
    const currency = info?.currency || '';
    price = currency && currency !== '元' ? `${price}${currency}` : price;
    return price;
}
